import {Router, ActivatedRoute, Params, NavigationEnd, NavigationStart} from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';


import { AccountService } from './../../services/account.service';
import { UtilsService } from './../../services/utils.service';
import { BasketService } from './../../services/basket.service';
import { environment } from '../../../../environments/environment';
import { AppService } from './../../services/app.service';
import { ProductsService } from './../../services/products.service';
import { HTTPClientService } from './../../services/httpclient.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../services/language.service';
import { NotificationsService } from '../../services/notifications.service';
import { ProdTagService } from '../../services/prodtag.service';

import { HierarchieService } from './../../services/hierarchie.service';

declare var ga: Function;
declare var $: any; import {isBrowser} from '../../thirdparty/isBrowser';
import { LauncherService } from '../../services/launcher.service';
declare var Swal: any;



@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
    providers: []
})
export class HeaderComponent implements OnInit {

    public state: {};

    public showLeft = false;
    public lang = null;

    public langsAvailable: any[] = [
        {value: 'fr', asset: '/assets/fr_flag.png'},
        {value: 'en', asset: '/assets/us_flag.jpg'},
    ];

    public searchStr = '';

    public logged: boolean;
    public isProd: boolean = environment.production;

    public fieldToDisplay: string[] = ['name'];

    public basketLength = 0;

  public pageIsNotAboutUs = null;


  public whiteMenu = [
        {
            value: this.translate.instant('Abonnement'),
            link: '/content',
            logo: 'assets/vr_logo.png',
            alt: 'Logo of Exercices VR',
            title: 'Exercices VR logo',
            isDropDownMenu: false,
            isBold: true,
        },
        {
            value: this.translate.instant('SafetyBox'),
            link: '/safetybox-vr',
            isDropDownMenu: false,
            logo: 'assets/vr_logo.png',
            alt: 'Logo of SafetyBoxVR',
            title: 'SafetyBoxVR logo',
            isBold: true,
        },
        {
            value: 'SafetyDay',
            link: '/safety-day',
            isDropDownMenu: false,
            logo: 'assets/vr_logo.png',
            alt: 'Logo of SafetyDay VR',
            title: 'SafetyDay VR logo',
            // dropDownTarget: 'SafetyDay',
            isBold: true,
            // subMenuList: [
            //     {
            //         value: this.translate.instant('Journée sécurité'),
            //         link: '/safety-day'
            //     },
            //     {
            //         value: this.translate.instant("Ateliers"),
            //         link: "/safety-day/workshops",
            //         // "tags": 221,
            //         // "param": "device"
            //     }
            // ]
        },
        {
            value: this.translate.instant('Usages'),
            link: '#',
            isDropDownMenu: true,
            dropDownTarget: 'Usages',
            subMenuList: [
                {
                    value: this.translate.instant('Safety quarter hour'),
                    link: '/safety-quarter-hour'
                },
                {
                    value: this.translate.instant('Accueil Sécurité'),
                    link: '/security-welcome'
                },
                {
                    value: this.translate.instant('Digital training'),
                    link: '/digital-learning'
                },
                {
                    value: this.translate.instant('Distanciel'),
                    link: '/virtual-campus'
                },
                {
                    value: this.translate.instant('Pédagogie'),
                    link: '/vr-training-osh',
                },
            ]
        },
        {
            value : this.translate.instant("Secteurs"),
            link : "#",
            isDropDownMenu : true,
            dropDownTarget : "Secteurs",
            subMenuList : [
                {
                    value : this.translate.instant("Construction"),
                    link : "/industry-construction"
                },
                {
                    value : this.translate.instant("Road Safety"),
                    link : "/industry-road-safety"
                },
                {
                    value : this.translate.instant("Pharmaceutical"),
                    link : "/industry-pharmaceutical"
                },
                {
                    value : this.translate.instant("Petrochemicals"),
                    link : "/industry-petrochemicals"
                },
                {
                    value : this.translate.instant("Logistics"),
                    link : "/industry-logistics"
                },
                {
                    value : this.translate.instant("Manufacturing"),
                    link : "/industry-manufacturing"
                },
                {
                    value : this.translate.instant("Agroalimentaire"),
                    link : "/industry-food"
                },
                {
                    value : this.translate.instant("University"),
                    link : "/industry-university"
                },
                {
                    value : this.translate.instant("Santé"),
                    link : "/industry-health"
                },
                {
                    value : this.translate.instant("Waste Management"),
                    link : "/industry-waste-management"
                }
            ]
        },
        {
            value : this.translate.instant("About us"),
            link : "#",
            isDropDownMenu : true,
            dropDownTarget : "About us",
            subMenuList : [
                {
                    value: 'Immersive Factory',
                    link: '/about-us'
                },
                {
                    value: 'Echange Formation',
                    link: '/echangeformation'
                },
                {
                    value: this.translate.instant("Nos contributeurs"),
                    link: '/our-contributors'
                },
                {
                    value : this.translate.instant("Blog"),
                    link : "/blog"
                },
                {
                    value : this.translate.instant("R&D"),
                    link : "/research-innovation"
                },
                // {
                //     value: this.translate.instant('Events'),
                //     link: '/events'
                // },
                {
                    value: this.translate.instant('White Papers'),
                    link: '/white-papers'
                },
                {
                    value: this.translate.instant('Support & FAQ'),
                    link: '/faq'
                }
            ]
        }
    ];
    public activeSubmenuLink = false;
    public activeLink = false;
    public deleteButtons = false;



    public blackMenu = [

    ];

    public fillHighlighted = false;
    public manageGrp = false;
    public mode = 2;
    public activeMenu = '';
    public enableUserMenu = false;
    public devices = [];

    public showLauncherButton = false;

    launcherLink = environment.accountURL + ((this.language.lang && this.language.lang == 'fr') ? 'fr/' : '') + 'downloads';

    constructor(
        public accountService: AccountService,
        public utilsService: UtilsService,
        public basketService: BasketService,
        public appService: AppService,
        private router: Router,
        public language: LanguageService,
        public productsService: ProductsService,
        private httpService: HTTPClientService,
        public translate: TranslateService,
        public hierarchieService: HierarchieService,
        public launcherService: LauncherService,
        public notifs: NotificationsService,
        public prodTag: ProdTagService
    )
    {


        this.logged = this.accountService.isLogged();
        this.accountService.tokenUpdated.subscribe((token) => {
            if (!token){
                this.logged = this.accountService.isLogged();
            }
        });
        // bugfix for ng-select values
        for (const lang of this.langsAvailable){
            if (lang.value == this.translate.store.currentLang){
                this.lang = lang.value;
            }
        }
    }

    ngOnInit() {
      // track page change
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.pageIsNotAboutUs = event.url !== '/about-us' && event.url !== '/fr/a-propos' && event.url !== '/fr/a-propos/o' && event.url !== '/about-us/o';
        }
      })

      // var menu = this.whiteMenu.find(c => c["link"] == "/content")
        // this.prodTag.getAllProdTags()
        // .then((tags) => {
        //     try {
        //         this.devices = tags.find(c => c["id"] == 1)["tags"]
        //         this.devices.sort((a, b) => a["position"] - b["position"])
        //
        //         for (let device of this.devices.filter(e => e['id'] != 221)) {
        //             menu["subMenuList"].push({
        //                 "value": device["name"],
        //                 "link": "/content",
        //                 "tags": device["id"],
        //                 "param": "device"
        //             })
        //         }
        //
        //     } catch (error) {
        //
        //     }
        //
        //     // menu["subMenuList"].push({
        //     //     "value": "All workshops",
        //     //     "link": "/content"
        //     // })
        //
        // })

      if (this.appService.isViewerMode){
            this.router.events.subscribe((val) => {
                this.state = val;
                this.checkAccount();
            });
        }
        else{
            this.logged = this.accountService.isLogged();

            this.accountService.getUserInfoAsync()
            .then(() => {
                return this.hierarchieService.getInferiorGroups();
            })
            .then((res) => {
                if (res && this.accountService.userInfos.companyGroup && this.accountService.userInfos.id == this.accountService.userInfos.companyGroup.managedById ){
                    this.enableUserMenu = true;
                }
            });

            this.hierarchieService.getInferiorGroups()
            .then((res) => {
                if (res && res.length != 0){
                    this.manageGrp = true;
                }
            });
        }


      if (this.isProd && isBrowser()) {
            // Update Google analytics
            this.router.events.pipe(distinctUntilChanged((previous: any, current: any) => {
                // Subscribe to any `NavigationEnd` events where the url has changed
                if (isBrowser()){
                    if (current instanceof NavigationEnd) {
                        return previous.url === current.url;
                    }
                }
                return true;
            })).subscribe((x: any) => {
                if (typeof(ga) == 'function'){
                    ga('set', 'page', x.url);
                    ga('send', 'pageview');
                }
            });
        }
      this.checkAccount();

      this.language.langChange.subscribe(data => {
            this.lang = data;
        });
      this.checkLauncher();

    }


    checkAccount(){
        this.logged = this.accountService.isLogged();
    }


    // Check if one of the link in the menu is active in order to apply it a certain style
    checkActiveLink(menu){
        let url: string[] = [''];
        if (menu.tags && menu.param){
          url = this.language.navigateAccordingToLang([menu.link + '?tags=' + menu.tags + '#' + menu.param]);
        }
        else{
          url = this.language.navigateAccordingToLang([menu.link]);
        }
        if (url[0] && this.router.url === url[0]){
          this.activeLink = true;
        }
        else{
          this.activeLink = false;
        }
        if ((this.router.url == '/subscription') || this.router.url == '/fr/abonnement'){
          this.deleteButtons = true;
        }
        else {
          this.deleteButtons = false;
        }
        if (menu.submenu){
          return this.checkActiveSubmenuLink(menu.submenu);
        }
        else{
          return this.activeLink;
          return this.deleteButtons;
        }
    }

    // Check if one of the link in the submenu is active in order to also apply the active style to its parent's link
    checkActiveSubmenuLink(menu){
      let url: string[] = [''];
      for (const subMenu of menu){
        if (subMenu.submenu){
          for (const subSubMenu of subMenu.submenu){
            if (subSubMenu.tags && subSubMenu.param){
              url = this.language.navigateAccordingToLang([subSubMenu.link + '?tags=' + subSubMenu.tags + '#' + subSubMenu.param]);
            }
            else{
              url = this.language.navigateAccordingToLang([subSubMenu.link]);
            }
            if (url[0] && this.router.url === url[0]){
              this.activeSubmenuLink = true;
              break;
            }
            this.activeSubmenuLink = false;
          }
          if (this.activeSubmenuLink == true){
            break;
          }
        }
        else{
          if (subMenu.tags && subMenu.param){
            url = this.language.navigateAccordingToLang([subMenu.link + '?tags=' + subMenu.tags + '#' + subMenu.param]);
          }
          else{
            url = this.language.navigateAccordingToLang([subMenu.link]);
          }
          if (url[0] && this.router.url === url[0]){
            this.activeSubmenuLink = true;
            break;
          }
          this.activeSubmenuLink = false;
        }
      }
      return this.activeSubmenuLink;
    }



    logout() {
        this.accountService.logout();
        this.logged = this.accountService.isLogged();
        this.router.navigate(this.language.navigateAccordingToLang(['/']));
    }

    getCurrentLang(){
        return this.translate.store.currentLang;
    }

    changeLang(){
        this.language.changeLanguage(this.lang);
    }

    swalLangChange(){
        const langTable = {
            fr : '',
            en : ''
        };
        const deviseTable = {
            EUR : '',
            USD : '',
            GBP : ''
        };
        langTable[this.translate.store.currentLang] = 'checked';
        deviseTable[this.basketService.currentCurrency] = 'checked';
        Swal.fire({
            cancelButtonText: this.translate.instant('Annuler'),
            showCancelButton: true,
            html : `
            <div class="row">
            <div class="col-6">
            <div class="customLangSelector flagFr">
            <input type="radio" id="langFR" name="langSelector" value="fr" ` + langTable.fr + `>
            <label for="langFR">Français</label>
            </div>
            <div class="customLangSelector flagEn">
            <input type="radio" id="langEN" name="langSelector" value="en" ` + langTable.en + `>
            <label for="langEN">English</label>
            </div>
            </div>
            <div class="col-6">
            <div class="customDeviseSelector deviseEUR">
            <input type="radio" id="deviseEUR" name="deviseSelector" value="EUR" ` + deviseTable.EUR + `>
            <label for="deviseEUR">EUR</label>
            </div>
            <div class="customDeviseSelector deviseUSD">
            <input type="radio" id="deviseUSD" name="deviseSelector" value="USD" ` + deviseTable.USD + `>
            <label for="deviseUSD">USD</label>
            </div>
            <div class="customDeviseSelector deviseGBP">
            <input type="radio" id="deviseGBP" name="deviseSelector" value="GBP" ` + deviseTable.GBP + `>
            <label for="deviseGBP">GBP</label>
            </div>
            </div>
            </div>
            `,
            preConfirm: () => {
                return new Promise((resolve) => {
                    if (isBrowser()) { resolve({
                        deviseSelected : $('input[name=deviseSelector]:checked').val(),
                        langSelected : $('input[name=langSelector]:checked').val()
                    });
                    }
                });
            }
        })
        .then((res) => {
            if (res.value){
                if (res.value.deviseSelected && this.basketService.currentCurrency != res.value.deviseSelected){
                    this.basketService.changeCurrency(res.value.deviseSelected);
                }
                if (res.value.langSelected && this.translate.store.currentLang != res.value.langSelected){
                    this.language.changeLanguage(res.value.langSelected);
                }
            }
        });
    }

    actionToggle(){
      this.showLeft = !this.showLeft;
      return this.accountService.isToggled(this.showLeft);
    }

    goHomeOrScroll(){
        if (this.router.url == '/'){
            if (isBrowser()) { $('html, body').animate({ scrollTop: 0 }); }
        }
        else{
            this.router.navigate(this.language.navigateAccordingToLang(['/']));
        }
    }

    checkLauncher(){
        if (this.accountService.isLogged()){
            this.launcherService.getAccountDownloads()
            .then((res) => {
                if (res && res.length){
                    this.showLauncherButton = true;
                }
            });
        }
    }

}
